<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <!-- <v-list-item-avatar class="logo-group">
      <v-img
        :src="
          require('@/assets/logo.png')"
      />
    </v-list-item-avatar> -->

    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h3">
        <strong class="mr-1 font-weight-black">
          FET CAT3
        </strong>
        <p class="site-title-sub">
          Dashboard Library
        </p>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
// Utilities
import { get } from 'vuex-pathify'

export default {
  name: 'DefaultDrawerHeader',

  computed: {
    version: get('app/version')
  }
}
</script>

<style scoped>
.site-title-sub {
  margin-bottom: 0px;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9em;
}
/* .logo-group {
  margin-right: 0px !important;
} */
</style>
